import React from 'react';
import ReactDOM from 'react-dom';
import '../misc/axios-config';
import App from '../info/App';
import InfobubbleApp from '../infobubble/App';

require('../css/bizit.scss');
import('../misc/base.js');

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('[data-js-info], [data-js-btn]').forEach((el) => {
    const config = JSON.parse(el.dataset.jsInfo || el.dataset.jsBtn || '{}');
    config.stepContent = Array.from(el.querySelectorAll('[data-js-info-step]'))
      .map((el) => [el.dataset.jsInfoStep, el.innerHTML])
      .reduce((obj, item) => {
        const [k, v] = item;
        return {
          ...obj,
          [k]: v
        };
      }, {});

    if (Object.keys(el.dataset).includes('jsInfo')) {
      ReactDOM.render(<App config={config} />, el);
    } else {
      ReactDOM.render(<InfobubbleApp config={config} />, el);
    }
  });
});

document.querySelectorAll('a[data-fakelink]').forEach((el) => {
  const config = JSON.parse(el.dataset.fakelink || '{}');
  const scrollopts = { block: 'start', behavior: 'smooth', ...config.scroll };
  const jsapp = document.querySelector('[data-js-scrolltarget]') || document.querySelector('.bg-planes');
  el.addEventListener('click', (e) => {
    e.preventDefault();
    jsapp.scrollIntoView(scrollopts);
  });
});
