import React from 'react';

const LabelWFeedback = ({ label, feedback, children })=>{
  return (
    <label>
      <span className="ui-label">
        { label }
        { feedback && !['ok'].includes(feedback?.uxStatus)
          && <span> &bull; {
            feedback.uxStatus == 'wait' ?
            <span className="ui-label--wait">Validando...</span>
            :
            <span className="ui-label--error">Error: { feedback.message }</span>
          }</span>
        }
      </span>
      <br/>
      { children }
    </label>
  )
}

export default LabelWFeedback;
