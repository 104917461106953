import React from 'react';
import { Tooltip as Tippy } from 'react-tippy';
import { createUseStyles } from 'react-jss'
import 'react-tippy/dist/tippy.css';

const useStyles = createUseStyles({
  container: {
    width: 250,
    textAlign: 'left'
  }
});

const Tooltip = (props) => {
  const classes = useStyles();
  return (
    <Tippy
      html={
        <div className={classes.container}>
          {props.overlay}
        </div>
      }
      position="bottom"
      arrow={true}
      interactive={true}
      open={props.open}
      theme="light">
      {props.children}
    </Tippy>
  );
};

export default Tooltip;
