import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Mailcheck from 'mailcheck';
import LabelWFeedback from './LabelWFeedback';

Mailcheck.defaultTopLevelDomains.push('mx', 'com.mx');

let intervals = {};

const App = (props) => {
  let steps = props.config?.steps || [['nombre', 'email', 'telefono']];
  steps = [null, ...steps];
  const [currentStep, setCurrentStep] = useState(1);
  const [validFields, setValidFields] = useState([]);
  const [statusOnFields, setStatusOnFields] = useState({});
  const [fieldsDisabled, setFieldsDisabled] = useState(false);
  const [formSent, setFormSent] = useState(false);
  const [nombre, setNombre] = useState(null);
  const [telefono, setTelefono] = useState(null);
  const [email, setEmail] = useState(null);

  const itemsRef = useRef([]);

  const handleNombreChange = (ev) => {
    setNombre(ev.target.value);
    ev.target.setCustomValidity('');
  };
  const handleTelefonoChange = (ev) => {
    setTelefono(ev.target.value);
    ev.target.setCustomValidity('');
  };
  const handleEmailChange = (ev) => {
    setEmail(ev.target.value);
    ev.target.setCustomValidity('');
  };

  useEffect(() => {
    setValidFields((prev) => {
      if (nombre === null) return prev;
      prev = prev.filter((e) => e !== 'nombre');
      let slug = nombre.replace(/([^a-z\s])*/gi, '').replace(/\s\s+/g, ' ');
      let err = [];
      if (slug.split(' ').filter((k) => k.length >= 2).length < 2) {
        err.push('Debes incluir nombre y apellidos');
      }
      if (slug.length < 3) {
        err.push('Escribe tu nombre');
      }
      setStatusOnFields((status_prev) => {
        return {
          ...status_prev,
          nombre: {
            status: err.length ? 'error' : 'ok',
            uxStatus: 'wait',
            message: err.splice(-1),
            element: itemsRef.current.nombre
          }
        };
      });
      clearTimeout(intervals.nombre);
      intervals.nombre = setTimeout(() => {
        setStatusOnFields((status_prev) => {
          return { ...status_prev, nombre: { ...status_prev.nombre, uxStatus: status_prev.nombre.status } };
        });
      }, 1100);
      if (!err.length) {
        return [...prev, 'nombre'];
      }
      return prev;
    });
  }, [nombre]);

  useEffect(() => {
    setValidFields((prev) => {
      if (email === null) return prev;
      prev = prev.filter((e) => e !== 'email');
      let err = [];
      if (
        !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          email
        )
      ) {
        err.push('Tu email tiene que ser válido');
      }
      if (email.length < 3) {
        err.push('Escribe tu email');
      }
      let emailcheck = Mailcheck.run({ email: email });
      if (emailcheck?.domain.match(/(gmail|hotmail)\./i)) {
        err.push(`Tu email parece ser incorrecto, ¿tal vez quisiste escribir ${emailcheck.full}?`);
      }
      setStatusOnFields((status_prev) => {
        return {
          ...status_prev,
          email: {
            status: err.length ? 'error' : 'ok',
            uxStatus: 'wait',
            message: err.splice(-1),
            element: itemsRef.current.email
          }
        };
      });
      clearTimeout(intervals.email);
      intervals.email = setTimeout(() => {
        setStatusOnFields((status_prev) => {
          return { ...status_prev, email: { ...status_prev.email, uxStatus: status_prev.email.status } };
        });
      }, 1100);
      if (!err.length) {
        return [...prev, 'email'];
      }
      return prev;
    });
  }, [email]);

  useEffect(() => {
    setValidFields((prev) => {
      if (telefono === null) return prev;
      prev = prev.filter((e) => e !== 'telefono');
      let err = [];
      if (telefono.replace(/([^\d]+)/g, '').length != 10) {
        err.push('Debe ser a 10 dígitos');
      }
      if (telefono.length < 3) {
        err.push('Escribe tu teléfono');
      }
      setStatusOnFields((status_prev) => {
        return {
          ...status_prev,
          telefono: {
            status: err.length ? 'error' : 'ok',
            uxStatus: 'wait',
            message: err.splice(-1),
            element: itemsRef.current.telefono
          }
        };
      });
      clearTimeout(intervals.telefono);
      intervals.telefono = setTimeout(() => {
        setStatusOnFields((status_prev) => {
          return { ...status_prev, telefono: { ...status_prev.telefono, uxStatus: status_prev.telefono.status } };
        });
      }, 1100);
      if (!err.length) {
        return [...prev, 'telefono'];
      }
      return prev;
    });
  }, [telefono]);

  useEffect(() => {
    const itemsForStep = { ...itemsRef.current };
    const firstInput = Object.values(itemsForStep).find((it) => it);
    if (currentStep != 1)
      setTimeout(() => {
        firstInput?.focus();
      }, 100);
  }, [currentStep]);

  const formSubmit = async (ev) => {
    ev.preventDefault();
    let fieldsForStep = steps
      .slice(0)
      .splice(1, currentStep)
      .flat();
    if (validFields.length < fieldsForStep.length) {
      let input = Object.keys(statusOnFields)
        .map((key) => {
          return statusOnFields[key];
        })
        .find((item) => item.status === 'error');
      if (input) {
        input?.element.setCustomValidity(input.message[0]);
        input?.element.focus();
      } else {
        input = Object.values(itemsRef.current).find((t) => t);
        input?.setCustomValidity('Completa todos los campos');
        input?.focus();
      }
      ev.target.reportValidity();
      return;
    }

    setFieldsDisabled(true);
    try {
      const response = await axios.post('/api/lead', {
        lead: {
          nombre,
          telefono,
          email
        }
      });
      if (currentStep < steps.length - 1) {
        setCurrentStep(currentStep + 1);
      } else {
        setFormSent(true);
      }
    } catch (error) {
      alert(
        'Hubo un error en el envío de tus datos, favor de intentarlo más tarde o contactarnos por teléfono o WhatsApp.'
      );
      console.log(error.response);
    } finally {
      setFieldsDisabled(false);
    }
  };

  return (
    <>
      {!formSent ? (
        <form onSubmit={formSubmit}>
          {props.config?.stepContent?.[currentStep] && (
            <div dangerouslySetInnerHTML={{ __html: props.config.stepContent[currentStep] }} />
          )}
          {steps[currentStep].map((s, ix) => {
            return (
              (s.includes('nombre') && (
                <div className="p" key="nombre">
                  <LabelWFeedback label="Nombre" feedback={statusOnFields.nombre}>
                    <input
                      ref={(el) => (itemsRef.current.nombre = el)}
                      type="text"
                      disabled={fieldsDisabled}
                      className="inputtext full"
                      placeholder="Nombre y apellidos"
                      onChange={handleNombreChange}
                      defaultValue={nombre}
                      data-test-inputnombre
                    />
                  </LabelWFeedback>
                </div>
              )) ||
              (s.includes('email') && (
                <div className="p" key="email">
                  <LabelWFeedback label="Email" feedback={statusOnFields.email}>
                    <input
                      ref={(el) => (itemsRef.current.email = el)}
                      type="email"
                      disabled={fieldsDisabled}
                      className="inputtext full"
                      placeholder="ejemplo@empresa.com"
                      onChange={handleEmailChange}
                      defaultValue={email}
                      data-test-inputemail
                    />
                  </LabelWFeedback>
                </div>
              )) ||
              (s.includes('telefono') && (
                <div className="p" key="telefono">
                  <LabelWFeedback label="Teléfono" feedback={statusOnFields.telefono}>
                    <input
                      ref={(el) => (itemsRef.current.telefono = el)}
                      type="tel"
                      disabled={fieldsDisabled}
                      className="inputtext full"
                      placeholder="10 dígitos"
                      onChange={handleTelefonoChange}
                      defaultValue={telefono}
                      data-test-inputtelefono
                    />
                  </LabelWFeedback>
                </div>
              ))
            );
          })}
          <div className="p">
            <button disabled={fieldsDisabled} className="button">
              Enviar
            </button>
          </div>
        </form>
      ) : (
        <>
          {props.config.stepContent.after ? (
            <div dangerouslySetInnerHTML={{ __html: props.config.stepContent.after }} />
          ) : (
            <span>Tus datos han sido enviados correctamente</span>
          )}
        </>
      )}
    </>
  );
};

export default App;
