import axios from 'axios'
axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('[name=csrf-token]')?.content;

if(process.env.RAILS_ENV == 'test'){
  window.axios_pending_requests = 0

  axios.interceptors.request.use(config => {
    window.axios_pending_requests++
    return config
  }, Promise.reject)

  axios.interceptors.response.use(
    response => {
      window.axios_pending_requests--
      return response
    },
    error => {
      window.axios_pending_requests--
      return Promise.reject(error)
    }
  )
}
