import React, { useState } from 'react';
import { createUseStyles } from 'react-jss'
import Tooltip from '../elements/Tooltip';
import InfoApp from '../info/App';

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    paddingTop: 5
  },
  close: {
    position: 'absolute',
    top: -5,
    right: -5,
    textDecoration: 'none',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1.5rem',
    width: 20,
    height: 20,
    opacity: 0.5,
    '&:hover':{
      opacity: 1
    }
  }
});

const App = (props)=>{
  const styles = useStyles();
  const [tooltipVisible, setTooltipVisible] = useState(false);

  return (
    <div>
      <Tooltip placement="top" open={ tooltipVisible } overlay={
        <div className={styles.container}>
          <a href="/" onClick={ (ev)=>{ ev.preventDefault(); setTooltipVisible((t)=> !t ) } } className={styles.close}><span>&times;</span></a>
          <InfoApp config={ props.config }/>
        </div>
      }>
        <a className={`button full ${props.button_class}`} href="/" onClick={ (ev)=>{ ev.preventDefault(); setTooltipVisible((t)=> !t ) } }>{ props.button_text }</a>
      </Tooltip>
    </div>
  );
}

App.defaultProps = {
  button_class: '',
  button_text: 'Más información',
}

export default App;
