import Alpine from 'alpinejs';

require('../css/bizit.scss');
require('../misc/base.js');
require('./landings.js');

document.addEventListener('DOMContentLoaded', () => {
  Alpine.data('menu', () => ({
    open: false,
    toggle() {
      this.open = !this.open;
    }
  }));

  const menu = document.querySelector('[data-js-menu]');
  const menuBtn = document.querySelector('[data-js-menu-btn]');
  const menuItems = document.querySelector('[data-js-menu-items]');
  menu.setAttribute('x-data', 'menu');
  menuBtn.setAttribute('x-on:click.prevent', 'toggle');
  menuItems.setAttribute(':class', 'open ? "open" : ""');

  Alpine.start();
});
